export default [
  {
    path: 'banner',
    label: 'Home',
    offset: -30,
  },
  {
    path: 'features',
    label: 'Features',
    offset: 0,
  },
];
