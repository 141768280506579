/** @jsxRuntime classic */
/** @jsx jsx */
import React from "react"
import {jsx, Box, Container, Flex, Link as A, Text} from 'theme-ui'
import {Link} from '../link'
import Logo from '../logo'
import {domain} from "../../config"

export default function Footer() {
    return (
        <footer
            sx={{
                variant: 'layout.footer',
            }}
        >
            <Container
                sx={{
                    variant: 'layout.toolbar',
                    flexDirection: ['column', null, null, null, 'row'],
                    py: '40px',
                    justifyContent: "space-between",
                }}
            >

                <Flex sx={{
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                }}
                >
                    <Logo/>
                    <Text sx={styles.copyright}>
                        &copy; {new Date().getFullYear()} Roam Garden by <A href="https://twitter.com/VladyslavSitalo/">Vlad
                        Sitalo</A>
                    </Text>
                </Flex>

                {/*Redirects are configured at https://console.aws.amazon.com/amplify/home?region=us-east-1#/d2r6mnhzom46kd/settings/rewrites*/}
                <Box sx={styles.bottomMenu}>
                    <A sx={styles.link}
                       target="_blank"
                       href={`https://${domain}/issues`}>Report an issue</A>

                    <A sx={styles.link}
                       target="_blank"
                       href={`https://${domain}/community`}>Request a feature</A>
                </Box>

                <Box sx={styles.bottomMenu}>
                    <Link sx={styles.link}
                          variant="footer"
                          path="/privacy"
                          label="Privacy policy"/>
                    <Link sx={styles.link}
                          variant="footer"
                          path="/tos"
                          label="Terms Of Service"/>
                </Box>

                <Box sx={styles.bottomMenu}>
                    <A sx={styles.link}
                       target="_blank"
                       href={`https://help.roam.garden`}>Guides & FAQ</A>

                    <A sx={styles.copyright}
                       target="_blank"
                       href={`mailto:help@${domain}`}>help@{domain}</A>
                </Box>

            </Container>
        </footer>
    )
}

const styles = {
    copyright: {
        color: '#0F2137',
        opacity: 0.6,
        ml: '10px',
    },
    link: {
        color: '#02073E',
        lineHeight: 2.5,
        display: 'block',
        opacity: 0.8,
        transition: 'all 500ms ease',
        '&:hover': {
            opacity: 1,
        },
    },
    bottomMenu: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        flexDirection: "column",
        alignItems: 'center',
        a: {
            marginLeft: "1em",
            marginRight: "1em",
            color: '#02073E',
        },
    },
}
