/** @jsxRuntime classic */
/** @jsx jsx */
import {jsx, Container, Flex, Image, Link as A} from 'theme-ui'
import {Link} from 'components/link'
import Logo from '../logo'
import {DrawerProvider} from 'contexts/drawer/drawer.provider'
import lock from 'assets/lock.svg'
import userIcon from 'assets/user.svg'
import MobileDrawer from './mobileDrawer'
import menuItems from './header.data'
import React, {useEffect, useState} from "react"
import {Auth} from '../../common/amplify-wrapper'

import "./header.css"

function UserIndicator() {
    const [user, setUser] = useState("")

    useEffect(() => {
        (async () => {
            try {
                const user = await Auth.currentAuthenticatedUser()
                setUser(user.getUsername())
            } catch (e) {
                console.log(e)
            }
        })()
    }, [])


    const User = (img, name) => <>
        <Image src={img} alt=""/>
        {name}
    </>

    return <Link path="/user" sx={styles.loginBtn}>
        {user ? User(userIcon, user) : User(lock, "Login")}
    </Link>
}

export default function Header({className}) {
    return (
        <DrawerProvider>
            <header sx={styles.header} className={className}>
                <Container sx={styles.container}>
                    <Logo/>

                    <Flex as="nav" sx={styles.nav}>
                        {menuItems.map(({path, label}, i) => (
                            <Link to={`/#${path}`}
                                  sx={styles.nav.navLink}
                                  key={i}>
                                {label}
                            </Link>
                        ))}

                        <A href="https://help.roam.garden/#faq"
                           target="_blank"
                           sx={styles.nav.navLink}
                        >
                            FAQ
                        </A>

                    </Flex>
                    <UserIndicator/>
                    <Link
                        path="/app"
                        label="Plant a garden"
                        sx={styles.headerBtn}
                        variant="buttons.primary"
                    />

                    <MobileDrawer/>
                </Container>
            </header>
        </DrawerProvider>
    )
}

const styles = {
    headerBtn: {
        backgroundColor: '#3CC68A',
        fontSize: '16px',
        fontWeight: 'bold',
        letterSpacing: '-0.16px',
        borderRadius: '5px',
        color: '#ffffff',
        padding: '6.5px 24px',
        display: ['none', null, null, null, 'inline-block'],
        ml: ['0', null, null, 'auto', '0'],
        mr: ['0', null, null, '20px', '0'],
        transition: 'all 500ms ease',
        '&:hover': {
            color: '#fff',
            backgroundColor: 'secondary',
        },
    },
    loginBtn: {
        ml: 'auto',
        display: 'inline-flex',
        alignItems: 'center',
        fontSize: '15px',
        color: '#0F2137',
        fontWeight: 500,
        mr: '20px',
        img: {
            mr: '9px',
        },
    },
    header: {
        color: 'text_white',
        fontWeight: 'normal',
        py: '25px',
        width: '100%',
        backgroundColor: '#fff',
        transition: 'all 0.4s ease',
        borderBottom: '1px solid #E9EDF5',
        position: 'fixed',
        top: 0,
        left: 0,

        '&.sticky': {
            backgroundColor: 'background',
            color: 'text',
            py: '15px',
            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.06)',
        },
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: ['100%', null, null, null, null, '1170px', '1280px'],
    },
    nav: {
        ml: '50px',
        '@media screen and (max-width: 960px)': {
            display: 'none',
        },
        navLink: {
            fontSize: '16px',
            color: '#02073E',
            fontWeight: '400',
            cursor: 'pointer',
            lineHeight: '1.2',
            mr: '48px',
            transition: '500ms',
            '@media(max-width:1024px)': {
                mr: '30px',
            },
            ':last-child': {
                mr: '0',
            },
            '&:hover, &.active': {
                color: 'primary',
            },
        },
    },
}
