interface StripeData {
    publishableKey: string,
    earlyBirdMonthly: Price
    earlyBirdYearly: Price
}

export const StripeTest: StripeData = {
    publishableKey: "pk_test_51IFCfIGIsbtpBMBDgDCI1kfQ0Nig4O7gNE0u7TZEDijD6YIvwHNRVOD8Eqvhf7sFPjVn3hBZE8dE4NexiJzAtLyi00owMrbznT",
    earlyBirdMonthly: {
        id: "price_1IKzPAGIsbtpBMBD7SPaOgBu",
        valuePerMonth: 9,
        name: "Monthly",
    },
    earlyBirdYearly: {
        id: "price_1IKzPAGIsbtpBMBDUHW2bnxR",
        valuePerMonth: 7.5,
        name: "Yearly",
    },
}

export const StripeLive: StripeData = {
    publishableKey: "pk_live_51IFCfIGIsbtpBMBD2HfmhaTyigxpJO02i8Vmb44SLvVVZ0wcubeZlGHayknWpwmmpKIzP1p2pv6zZd5Pd8BGJJmR00jU1uuX53",
    earlyBirdMonthly: {
        ...StripeTest.earlyBirdMonthly,
        id: "price_1IKzuwGIsbtpBMBDrqvf5BqC",

    },
    earlyBirdYearly: {
        ...StripeTest.earlyBirdYearly,
        id: "price_1IKzuwGIsbtpBMBDJDEHdT3Q",
    },
}

export const StripeData = StripeLive

export interface Price {
    id: string,
    valuePerMonth: number,
    name: string
}

export const gardenApi = "gardenRApi"
export const domain = "roam.garden"
