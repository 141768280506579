/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:40cb18e6-1516-48a1-b469-3a49880508e3",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_h7lerBqSn",
    "aws_user_pools_web_client_id": "2fn2ckosmivo6k0q469inusah1",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "gardenRApi",
            "endpoint": "https://ijnl7gp452.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "temp-db-store54241-main",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
