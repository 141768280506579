import Amplify from "aws-amplify"
import awsExports from "../aws-exports"

Amplify.configure({
    ...awsExports,
    // Need this to do the user migration to new UserPool
    authenticationFlowType: 'USER_PASSWORD_AUTH',
})

export * from "aws-amplify"
